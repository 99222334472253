// import React, { useEffect, useRef, useState } from 'react';
// import { embedDashboard } from "@superset-ui/embedded-sdk";
// import { useLocation } from 'react-router-dom';

// const supersetUrl = 'https://superset.cm.clustrex.in';
// const backendUrl = 'https://api.cm.clustrex.in'; // FastAPI backend URL

// async function getDashboardToken(username) {
//     try {
//         const response = await fetch(`${backendUrl}/get_dashboard_token/`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ username })
//         });

//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         const { dashboards, guest_token } = data;
//         console.log("Fetched data:", { dashboards, guestToken: guest_token });
//         return { dashboards, guestToken: guest_token };
//     } catch (error) {
//         console.error("Error fetching dashboard token:", error);
//         return null;
//     }
// }

// function embedSupersetDashboard(guestToken, dashboardContainer) {
//     embedDashboard({
//         id: 'ec615946-98f5-4780-84e6-532a1c8f118e',
//         supersetDomain: supersetUrl,
//         mountPoint: dashboardContainer,
//         fetchGuestToken: () => guestToken,
//         dashboardUiConfig: { hideTitle: true }
//     });

//     // Optional: Adjust iframe styling
//     const iframe = dashboardContainer.querySelector("iframe");
//     if (iframe) {
//         iframe.style.width = '100%';
//         iframe.style.minHeight = '100vh';
//     }
// }

// function Dashboard() {
//     const location = useLocation();
//     const username = location.state?.username; // Retrieve username from router state
//     const [isLoading, setIsLoading] = useState(false); // Track loading state
//     const hasFetchedToken = useRef(false); // Track fetch status

//     useEffect(() => {
//         async function loadDashboard() {
//             if (username && !hasFetchedToken.current && !isLoading) {
//                 hasFetchedToken.current = true; // Set ref to prevent further calls
//                 setIsLoading(true); // Set loading state

//                 const dashboardInfo = await getDashboardToken(username);
//                 setIsLoading(false); // Reset loading state

//                 if (dashboardInfo) {
//                     console.log("Dashboard info:", dashboardInfo);
//                     if (dashboardInfo.dashboards && dashboardInfo.dashboards.length > 0) {
//                         const appContainer = document.querySelector(".App");
//                         let count = 1;

//                         dashboardInfo.dashboards.forEach(dashboard => {
//                             const dashboardContainer = document.createElement("div");
//                             dashboardContainer.className = `dashboard-container-${count}`; // Add a class for styling (optional)
//                             appContainer.appendChild(dashboardContainer); // Append the new container to the App

//                             embedSupersetDashboard(dashboardInfo.guestToken, dashboardContainer);
//                             count++; // Increment count for unique class names
//                         });
//                     } else {
//                         alert('Dashboard not found for this user');
//                     }
//                 } else {
//                     alert('Error fetching dashboard information.');
//                 }
//             } else if (!username) {
//                 alert('Username not provided.');
//             }
//         }

//         loadDashboard();
//     }, [username, isLoading]); // username and isLoading are dependencies

//     return (
//         <div className="App">
//             {/* Superset Dashboards will be embedded here dynamically */}
//         </div>
//     );
// }

// export default Dashboard;

import React, { useEffect, useRef, useState } from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useLocation } from 'react-router-dom';

const supersetUrl = 'https://superset.cm.clustrex.in';
const backendUrl = 'https://api.cm.clustrex.in'; // FastAPI backend URL

async function getDashboardToken(username) {
    try {
        const response = await fetch(`${backendUrl}/get_dashboard_token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const { dashboards, guest_token } = data;
        console.log("Fetched data:", { dashboards, guestToken: guest_token });
        return { dashboards, guestToken: guest_token };
    } catch (error) {
        console.error("Error fetching dashboard token:", error);
        return null;
    }
}

function embedSupersetDashboard(guestToken, dashboardContainer) {
    embedDashboard({
        id: 'ec615946-98f5-4780-84e6-532a1c8f118e',
        supersetDomain: supersetUrl,
        mountPoint: dashboardContainer,
        fetchGuestToken: () => guestToken,
        dashboardUiConfig: { hideTitle: true }
    });

    // Optional: Adjust iframe styling
    const iframe = dashboardContainer.querySelector("iframe");
    if (iframe) {
        iframe.style.width = '100%';
        iframe.style.minHeight = '100vh';
    }
}

function Dashboard() {
    const location = useLocation();
    const username = location.state?.username; // Retrieve username from router state
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const hasFetchedToken = useRef(false); // Track fetch status

    useEffect(() => {
        async function loadDashboard() {
            if (username && !hasFetchedToken.current && !isLoading) {
                hasFetchedToken.current = true; // Set ref to prevent further calls
                setIsLoading(true); // Set loading state

                const dashboardInfo = await getDashboardToken(username);
                setIsLoading(false); // Reset loading state

                if (dashboardInfo) {
                    console.log("Dashboard info:", dashboardInfo);
                    if (dashboardInfo.dashboards && dashboardInfo.dashboards.length > 0) {
                        const appContainer = document.querySelector(".App");
                        let count = 1;

                        dashboardInfo.dashboards.forEach(dashboard => {
                            const dashboardContainer = document.createElement("div");
                            dashboardContainer.className = `dashboard-container-${count}`; // Add a class for styling (optional)
                            appContainer.appendChild(dashboardContainer); // Append the new container to the App

                            embedSupersetDashboard(dashboardInfo.guestToken, dashboardContainer);
                            count++; // Increment count for unique class names
                        });
                    } else {
                        alert('Dashboard not found for this user');
                    }
                } else {
                    alert('Error fetching dashboard information.');
                }
            } else if (!username) {
                alert('Username not provided.');
            }
        }

        loadDashboard();
    }, [username, isLoading]); // username and isLoading are dependencies

    return (
        <div className="App">
            {/* Superset Dashboards will be embedded here dynamically */}
        </div>
    );
}

export default Dashboard;