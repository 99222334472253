// Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    
    // Retrieve username from localStorage
    const username = localStorage.getItem('username');

    const handleNavigate = (dashboard) => {
        if (dashboard === 'dashboard1') {
            navigate('/dashboard1', { state: { username } });
        } else if (dashboard === 'dashboard2') {
            navigate('/dashboard2', { state: { username } });
        }
    };

    return (
        <div>
            <h2>Welcome, {username}</h2>
            <button onClick={() => handleNavigate('dashboard1')}>Go to Dashboard 1</button>
            <button onClick={() => handleNavigate('dashboard2')}>Go to Dashboard 2</button>
        </div>
    );
};

export default Home;
