// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import Dashboard1 from './Dashboard1';
import Dashboard2 from './Dashboard2';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/dashboard1" element={<Dashboard1 />} />
                <Route path="/dashboard2" element={<Dashboard2 />} />
            </Routes>
        </Router>
    );
}

export default App;
